<template>
    <div class="panel-list-new panel-content">
        <div class="row review-component">
            <b-card class="col-lg-12 main-content" v-cloak>
                <div class="panel-list">
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass">Ledger and Media info

                            </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>Is Digital Request?</label>
                                <p v-if="Request.IsDigitalMedia == true">
                                    YES
                                </p>
                                <p v-if="Request.IsDigitalMedia == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsDigitalMedia == true">
                                <label>Digital Media</label>
                                <p>
                                    Search, Interactive, Social, Video, Bundled, Unbundled, Digital Audio
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Media Type</label>
                                <p v-if="!Request.MediaType">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.MediaType }}
                                </p>
                                <p v-if="Request.MediaType === 'TV' || Request.MediaType === 'Radio'">
                                    <span class="d-flex" v-if="Request.CallLetters">Call Letters : {{
                                        Request.CallLetters
                                    }}</span>
                                    <span class="d-flex" v-if="!Request.CallLetters">Call Letters : N/A</span>
                                    <span class="d-flex" v-if="Request.MarketNumbers">Market Numbers : {{
                                        Request.MarketNumbers }}</span>
                                    <span class="d-flex" v-if="!Request.MarketNumbers">Market Numbers : N/A</span>
                                    <span class="d-flex" v-if="Request.MarketName">Market Name : {{
                                        Request.MarketName
                                    }}</span>
                                    <span class="d-flex" v-if="!Request.MarketName">Market Name : N/A</span>
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Is Media Rep Request</label>
                                <p v-if="Request.IsMediaRepRequest == true">
                                    YES
                                </p>
                                <p v-if="Request.IsMediaRepRequest == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="VendorInvoiceCopyFiles.length > 0">
                                <label>Please attach an invoice copy</label>
                                <upload-file-holder :Files="VendorInvoiceCopyFiles"></upload-file-holder>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass">External Vendor Form
                            </label>
                        </b-row>
                        <b-row class="panel-content row">
                            <b-col :cols="ColumnsClass" v-if="CompletedForms.length > 0">
                                <label>Completed Forms</label>
                                <upload-file-holder :Files="CompletedForms"></upload-file-holder>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass"> Credit Card Info
                            </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?</label>
                                <p v-if="Request.IsCreditCardAccepted == true">
                                    YES
                                </p>
                                <p v-if="Request.IsCreditCardAccepted == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsCreditCardAccepted == true">
                                <label>Are there fees or conditions/limitations in accepting payment via credit card for this organization? An example would be that the vendor charges a 3% fee on all transactions, or that the companies credit card information must be held on file with the vendor for immediate payment processing.</label>
                                <p v-if="Request.AreThereConditionsOrLimitations == true">YES</p>
                                <p v-if="Request.AreThereConditionsOrLimitations == false">NO</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AreThereConditionsOrLimitations == true">
                                <label>What the Limitations Are</label>
                                <p>{{ Request.CreditLimitation }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AreThereConditionsOrLimitations == true">
                                <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                                <p v-if="Request.HasACHPaymentSetup == true">YES</p>
                                <p v-if="Request.HasACHPaymentSetup == false">NO</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AreThereConditionsOrLimitations == true">
                                <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                                <p v-if="Request.HasBankLetterInvoice == true">YES</p>
                                <p v-if="Request.HasBankLetterInvoice == false">NO</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>Bank Name</label>
                                <p>{{ Request.BankName }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>COUNTRY</label>
                                <p>{{ Request.BankCountry }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>BANK ADDRESS LINE 2</label>
                                <p>{{ Request.BankAddressLine1 }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>BANK ADDRESS LINE 2</label>
                                <p>{{ Request.BankAddressLine2 }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankState">
                                <label>BANK STATE</label>
                                <p>{{ Request.BankState }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankCity">
                                <label>BANK CITY</label>
                                <p>{{ Request.BankCity }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankZipCode">
                                <label>ZIP CODE</label>
                                <p>{{ Request.BankZipCode }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>Account name</label>
                                <p>{{ Request.BankAccountName }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>Account number</label>
                                <p>{{ Request.BankAccountNumber }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>Routing number</label>
                                <p>{{ Request.BankRoutingNumber }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasBankLetterInvoice == true">
                                <label>REMITTANCE ADVICE EMAIL</label>
                                <p>{{ Request.RemittanceEmailAddress }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="BankLetterInvoice.length > 0">
                                <label>PLEASE ATTACH BANK LETTER, INVOICE, OR VOIDED CHECK.</label>
                                <upload-file-holder :Files="BankLetterInvoice"></upload-file-holder>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsCreditCardAccepted == false">
                                <label>Would the vendor like to be set up by ACH Payment?</label>
                                <p v-if="Request.ACHPayment == true">
                                    YES
                                </p>
                                <p v-if="Request.ACHPayment == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.ACHPayment == true">
                                <label>You must obtain a bank letter from the vendor’s bank, or a voided check to setup ACH payments. Have you collected this information?</label>
                                <p v-if="Request.IsBankingInfoCollected == true">YES</p>
                                <p v-if="Request.IsBankingInfoCollected == false">NO</p>
                                <p v-if="!Request.IsBankingInfoCollected">N/A</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="CreditCardFiles.length > 0">
                                <label>Attachment(s)</label>
                                <upload-file-holder :Files="CreditCardFiles"></upload-file-holder>
                             </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass"> Setup Info
                            </label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>VENDOR BUSINESS NAME</label>
                                <p>
                                    {{ Request.VendorBusinessName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Identification Type</label>
                                <p>
                                    {{ formatIdentityTypeLabel(Request.IdentificationType) }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Identification Number</label>
                                <p>
                                    {{ formatIdentityType(Request[Request.IdentificationType]) }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="IdentificationFiles.length > 0">
                                <label>{{ AttachmentLabel }}</label>
                                <upload-file-holder :Files="IdentificationFiles"></upload-file-holder>

                            </b-col>
                            <b-col cols="lg-4">
                                <label>VENDOR REMITTANCE NAME</label>
                                <p>
                                    {{ Request.VendorRemittanceName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>COUNTRY</label>
                                <p>{{ Request.RemittanceCountry }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>STREET ADDRESS LINE 1</label>
                                <p>{{ Request.StreetAddressLine1 }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>STREET ADDRESS LINE 2</label>
                                <p>{{ Request.StreetAddressLine2 }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceCity">
                                <label>CITY</label>
                                <p>{{ Request.RemittanceCity }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceState">
                                <label>STATE</label>
                                <p>{{ Request.RemittanceState }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceZipCode">
                                <label>ZIP CODE</label>
                                <p>{{ Request.RemittanceZipCode }}</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>VENDOR EMAIL</label>
                                <p v-if="Request.RemittanceAdviceEmail">
                                    {{ Request.RemittanceAdviceEmail }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>DID THE VENDOR SPECIFY THEIR URL ON THE FORM THE VENDOR COMPLETED?</label>
                                <p v-if="Request.UrlSpecifiedInForm == true">
                                    Yes
                                </p>
                                <p v-if="Request.UrlSpecifiedInForm == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="SampleMediaKitFiles.length > 0">
                                <label>Sample Media Kit</label>
                                <upload-file-holder :Files="SampleMediaKitFiles"></upload-file-holder>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-12">
                                <label>Who at the vendor provided this information?</label>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Name</label>
                                <p>
                                    {{ Request.InfoFromName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Title</label>
                                <p>
                                    {{ Request.InfoFromTitle }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Telephone Number</label>
                                <p>
                                    {{ Request.InfoFromPhone }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col cols="lg-4">
                                <label>How was this information obtained?</label>
                                <p>
                                    <span v-if="Request.InfoFromSourceIsVerbal">Verbal</span>
                                    <span v-if="Request.InfoFromSourceIsVerbal && Request.InfoFromSourceIsWritten">
                                        &
                                    </span>
                                    <span v-if="Request.InfoFromSourceIsWritten">Written</span>
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InfoFromSourceVerbal">
                                <label>Verbal</label>
                                <p>
                                    {{ Request.InfoFromSourceVerbal }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                                <label>Written</label>
                                <p>
                                    {{ Request.InfoFromSourceWritten }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                                <label>Written Communication File</label>
                                <div>
                                    <upload-file-holder :Files="SetupInfoSource"></upload-file-holder>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass"> Employee Relationship
                            </label>
                        </b-row>
                        <b-row class="panel-content" v-show="Request.EmployeeVendorRelationship.length > 0">
                            <template v-if="!IsNoneSelected">
                                <b-col :cols="ColumnsClass" v-for="item in Request.EmployeeVendorRelationship"
                                    :key="item.RelationshipName">
                                    <label>{{ GetRelationshipLabel(item) }}</label>
                                    <div>
                                        <p>{{ Request.EmployeeVendorRelationshipDetails[item.RelationshipName] }}</p>
                                    </div>
                                </b-col>
                            </template>
                            <b-col :cols="ColumnsClass" v-if="IsNoneSelected">
                                <label>Employee Relationship With Vendor</label>
                                <p>None</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Has any form of compensation or gifts of any type been offered to you or any person
                                    related to you in exchange for placing an order with this vendor?</label>
                                <p v-if="Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true">
                                    YES</p>
                                <p v-if="Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == false">
                                    NO</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.CompensationReceived > 0">
                                <label>Compensation Received</label>
                                <p>${{ Request.CompensationReceived }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label :class="'col-' + ColumnsClass"> Employee Info
                            </label>
                        </b-row>
                        <b-row class="panel-content row">
                            <!-- <b-col :cols="ColumnsClass">
                            <label>Employee Title</label>
                            <div>{{Request.EmployeeTitle}}</div>
                        </b-col> -->
                            <b-col :cols="ColumnsClass">
                                <label>Employee Phone Number</label>
                                <p v-if="!Request.EmployeePhoneNumber">
                                    N/A
                                </p>
                                <p>{{ Request.EmployeePhoneNumber }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Entity</label>
                                <p>{{ Request.Entity }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Employee Agency</label>
                                <p>{{ Request.EmployeeAgency }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Client Code</label>
                                <p>{{ Request.ClientCode }}</p>
                            </b-col>
                        </b-row>
                         <b-row class="panel-content" v-if="Request.CompletedCode">
                            <b-col cols="lg-4">
                                <label>Completed Code</label>
                                <p>{{ Request.CompletedCode }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body" v-if="VendorValidationFiles.length > 0">
                        <b-row class="panel-title">
                            <label class="col-6">Vendor Validation Attachment</label>
                        </b-row>
                        <b-row class="panel-content row">
                            <b-col :cols="ColumnsClass" v-if="VendorValidationFiles.length > 0">
                                <label>Attachment(s)</label>
                                <upload-file-holder :Files="VendorValidationFiles"></upload-file-holder>
                             </b-col>
                        </b-row>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import UploadFileHolder from '../../Common/UploadFileHolder';
export default {
    components: {
        'UploadFileHolder': UploadFileHolder
    },
    data() {
        return {
            CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee (name) ",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member(name)",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "RelationshipWithAgencyClient"
            },
            {
                "label": "Vendor of Former employee",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Vendor was former employee of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Vendor has a relationship w/ another Agency employee (list employee name)",
                value: "RelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ],
            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },

    props: {
        Request: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        VendorValidationFiles: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        IsAuditTrail: {
            required: false,
            type: Boolean,
            default: false,
        },
        Columns: {
            type: Number,
            default: 4,
            required: false,
        },
        vendorType: {
            type: String,
            required: false,
        },
    },
    computed: {
        AttachmentLabel() {
            return this.Request.IdentificationType == 'SocialSecurityNumber' || this.Request.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        ColumnsClass() {
            return `lg-${this.Columns}`;
        },
        IsNoneSelected() {
            return this.Request.EmployeeVendorRelationship.length == 1 && this.Request.EmployeeVendorRelationship.findIndex(val => val.RelationshipName == "NONE") > -1;
        },
        CreditCardFiles() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "CreditCardFiles");
            }
            return files;
        },
        VendorInvoiceCopyFiles() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "VendorInvoiceCopyFiles");
            }
            return files;
        },
        SampleMediaKitFiles() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "SampleMediaKitFiles");
            }
            return files;
        },
        IdentificationFiles() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "IdentificationFiles");
            }
            return files;
        },
        SetupInfoSource() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "SetupInfoSource");
            }
            return files;
        },
        CompletedForms() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "CompletedForms");
            }
            return files;
        },
        GetInfoSource() {
            let text = "";
            if (this.Request.InfoFromSourceIsVerbal) {
                text += "Verbal"
            }
            if (this.Request.InfoFromSourceIsWritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        },
        BankLetterInvoice() {
            let files = [];
            if (this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "BankLetterInvoice");
            }
            return files;
        }
    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxID") {
                    return "US Tax ID";
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxID" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        ConvertToBool(mediavendorInfo) {
            mediavendorInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder = mediavendorInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder == "true" ? true : false;
            mediavendorInfo.IsCreditCardAccepted = mediavendorInfo.IsCreditCardAccepted == "true" ? true : false;
            mediavendorInfo.IsMediaRepRequest = mediavendorInfo.IsMediaRepRequest == "true" ? true : false;
            mediavendorInfo.UrlSpecifiedInForm = mediavendorInfo.UrlSpecifiedInForm == "true" ? true : false;
            mediavendorInfo.AreThereConditionsOrLimitations = mediavendorInfo.AreThereConditionsOrLimitations == "true" ? true : false;
            //mediavendorInfo.IsDigitalMedia = mediavendorInfo.IsDigitalMedia == "true" ? true : false;
        },

        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value.RelationshipName;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },

    },
};
</script>

<style scoped>
.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}
</style>
